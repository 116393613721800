import { render, staticRenderFns } from "./EventCardG.vue?vue&type=template&id=520ee0d4&scoped=true"
import script from "./EventCardG.vue?vue&type=script&lang=js"
export * from "./EventCardG.vue?vue&type=script&lang=js"
import style0 from "./EventCardG.vue?vue&type=style&index=0&id=520ee0d4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520ee0d4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShareModalSocial: require('/tmp/build_5f357c0d/components/Share/Modal/Social.vue').default,ShareModalView: require('/tmp/build_5f357c0d/components/Share/Modal/View.vue').default})
