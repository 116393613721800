

import { copyToClipboard } from "@/util/copy";
export default {
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
    url: { type: String, required: true },
    className: { type: String, default: '' }
  },
  computed: {

  },
  data() {
    return {
      showToast: false
    }
  },
  methods: {
    onFacebookShareClick() {
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}`;
      window.open(facebookShareUrl, "_blank",);
    },
    onWhatsAppShareClick() {
      const shareData = {
        title: this.title,
        text: this.text,
        url: this.url.trim()
      };

      const showWhatsAppShare = () => {
        const whatsappMessage = `${shareData.text} ${shareData.url}`;
        const encodedMessage = encodeURIComponent(whatsappMessage);


        const whatsAppDialogUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
        window.open(whatsAppDialogUrl, '_blank');

      };
      showWhatsAppShare()
    },


    onCopyLinkClick() {
      console.log('pase por aqui')
      copyToClipboard(this.url);
      this.showToast = true
    }

  }
};
