



export default {
  props: {
    title: { type: String, required: true },
    backgroundColor: { type: String, default: '#25D366' },
    size: { type: Number, default: 50 },

  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  }
};
