import { render, staticRenderFns } from "./PromoRegister.vue?vue&type=template&id=2e99095a&scoped=true"
import script from "./PromoRegister.vue?vue&type=script&lang=js"
export * from "./PromoRegister.vue?vue&type=script&lang=js"
import style0 from "./PromoRegister.vue?vue&type=style&index=0&id=2e99095a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e99095a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserPromoRegisterBanner: require('/tmp/build_5f357c0d/components/User/Promo/RegisterBanner.vue').default})
