
export default {
  props: {
    value: Boolean
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    closeModal() {
      this.isOpen = false;
    }
  }
};
